import React, { useState, PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";

// Helpers
import { LINKS } from "helpers/constants";

// Icons
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingIcon from "@mui/icons-material/Settings";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Components
import HeaderSearch from "./HeaderSearch";
// import HeaderUser from './HeaderUser';

// Material-UI
import { Theme } from "@mui/material/styles";
import  { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";

interface Props extends PropsWithChildren {}

const Header: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [avatarEl, setAvatarEl] = useState<null | HTMLElement>(null);

  return (
    <AppBar position="static" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Hidden mdUp>{props.children}</Hidden>
        <Hidden smDown>
          <ButtonBase className={classes.logoButton}>
            <img alt="logo" src="/logo-white.svg" className={classes.logo} />
          </ButtonBase>
        </Hidden>
        <HeaderSearch />
        <Hidden smDown>
          <Tooltip title="Notifications" aria-label="notifications">
            <IconButton
              aria-label="show 17 new notifications"
              color="inherit"
              size="large"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings" aria-label="settings">
            <Link
              component={RouterLink}
              to={LINKS.APP_SETTINGS}
              underline="none"
              color="inherit"
            >
              <IconButton aria-label="settings" color="inherit" size="large">
                <SettingIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </Hidden>
        <Hidden smUp>
          <IconButton
            aria-label="more"
            color="inherit"
            onClick={(e) => setAvatarEl(e.currentTarget)}
            size="large"
          >
            <Badge badgeContent={17} color="error">
              <MoreVertIcon />
            </Badge>
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={avatarEl}
            open={Boolean(avatarEl)}
            elevation={2}
            onClose={(e) => setAvatarEl(null)}
            
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <MenuItem>
              <ListItemIcon className={classes.iconWrapper}>
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon fontSize="small" />
                </Badge>
              </ListItemIcon>
              <Typography variant="inherit">Notifications</Typography>
            </MenuItem>
            <Divider light className={classes.userMenuDivider} />
            <Link
              component={RouterLink}
              to={LINKS.APP_SETTINGS}
              underline="none"
              color="inherit"
            >
              <MenuItem>
                <ListItemIcon className={classes.iconWrapper}>
                  <SettingIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Settings</Typography>
              </MenuItem>
            </Link>
          </Menu>
        </Hidden>
        {/* <HeaderUser/> */}
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.theme.main,
    color: theme.palette.theme.contrastText,
  },
  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  logoButton: {
    borderRadius: theme.shape.borderRadius,
  },
  logo: {
    maxHeight: 40,
    padding: theme.spacing(0, 0.5),
  },
  userMenuDivider: {
    minWidth: 200,
  },
  iconWrapper: {
    minWidth: 30,
  },
}));

export default Header;
