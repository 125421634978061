import React from "react";

// Material-UI
import { Theme } from "@mui/material/styles";
import  { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  height?: number;
  fullscreen?: boolean;
}

const Loading: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root} data-full={props.fullscreen}>
      <CircularProgress color="secondary" />
    </div>
  );
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    flexGrow: 1,
    display: "grid",
    placeItems: "center",
    height: (props) => (props.height ? props.height : "initial"),
    "&[data-full]": {
      height: "100vh",
      width: "100vw",
    },
  },
}));

export default Loading;
