import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { Link as RouterLink } from "react-router-dom";

import { clearToken } from "utils/auth";
import { IS_LOGGED_IN } from "graphql/queries/isLoggedIn";
import { LINKS } from "helpers/constants";

// Icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

// Material-UI
import { Theme } from "@mui/material/styles";
import  { makeStyles } from "@mui/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
// import IconButton from '@mui/material/IconButton';
// import Hidden from '@mui/material/Hidden';
import ListItemIcon from "@mui/material/ListItemIcon";

interface Props {}

const HeaderUser: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [avatarEl, setAvatarEl] = useState<null | HTMLElement>(null);

  const { data: loggedIn, client } = useQuery(IS_LOGGED_IN);

  const [userInfo, setUserInfo] = useState<any>({});

  useEffect(() => {
    const firstName = _get(loggedIn, "user.firstName") || "";
    const lastName = _get(loggedIn, "user.lastName") || "";

    setUserInfo({
      firstName,
      lastName,
      initials: firstName.substr(0, 1) + lastName.substr(0, 1),
    });
  }, [loggedIn]);

  const handleLogout = async (
    event: React.MouseEvent<HTMLLIElement | MouseEvent>
  ) => {
    setAvatarEl(null);
    clearToken();
    try {
      await client.cache.reset();
    } catch (error) {
      console.log("Unable to clear cache ", error);
    }

    if (window !== undefined) {
      window.location.href = LINKS.LOGIN;
    }
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setAvatarEl(null);
    event.stopPropagation(); // Prevent event propagation
  }

  return (
    <React.Fragment>
      {/* <Hidden smUp>
				<IconButton className={classes.avatarIconButton} onClick={e => setAvatarEl(e.currentTarget)}>
					<Avatar className={classes.avatar}>{userInfo.initials}</Avatar>
				</IconButton>
			</Hidden>
			<Hidden xsDown> */}
      <ButtonBase
        className={classes.avatarButton}
        onClick={(e) => setAvatarEl(e.currentTarget)}
      >
        <Avatar className={classes.avatar}>{userInfo.initials}</Avatar>
        <Typography variant="h6" className={classes.userName}>
          {userInfo.firstName} {userInfo.lastName}
        </Typography>
      </ButtonBase>
      {/* </Hidden> */}
      <Menu
        id="simple-menu"
        anchorEl={avatarEl}
        open={Boolean(avatarEl)}
        elevation={2}
        onClose={(e) => setAvatarEl(null)}
        
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        
        <Link component={RouterLink} to="/profile" underline="hover" onClick={handleLinkClick}>
        <MenuItem>
          <ListItemIcon className={classes.iconWrapper}>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Profile</Typography>
        </MenuItem>
        </Link>
        
        <Divider light className={classes.userMenuDivider} />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.iconWrapper}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  avatarIconButton: {
    padding: 0,
  },
  avatarButton: {
    padding: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius,

    width: "100%",
    justifyContent: "flex-start",
  },
  avatar: {
    // color: theme.palette.secondary.contrastText,
    // backgroundColor: theme.palette.secondary.main,
    // border: `2px solid ${theme.palette.secondary.contrastText}`,

    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.contrastText,
    border: `2px solid ${theme.palette.secondary.main}`,

    height: 40,
    width: 40,

    fontSize: "1.8rem",
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  userName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userMenuDivider: {
    minWidth: 200,
  },
  iconWrapper: {
    minWidth: 30,
  },
}));

export default HeaderUser;
