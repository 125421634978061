import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";
// import { setupCache } from 'axios-cache-adapter';
// import { localCache } from '../utils/storage';
import {
  fetchStatusHandler,
  accessTokenInterceptor,
  responseRefreshTokenInterceptor,
} from "utils/api-helpers";
import { generateSession } from "utils/generate-session";
// import {
//   refreshTokenRequestInterceptor,
//   refreshTokenResponseInterceptor,
// } from '../utils/refreshToken';
// import { setAuthTokenInterceptor } from './../utils/setAuthorizationToken'
// Set up caching
// const cache = setupCache({
// 	maxAge: 1000 * 60 * 1000,
// 	readHeaders: true,
// 	store: localCache,
// 	debug: true,
// 	invalidate: async (cfg, req) => {
// 		try {
// 			const res = await cfg.store.getItem(cfg.uuid);
// 			if (res != null && res.data != null) {
// 				if (res.data.status === 204 && res.data.data === '') {
// 					await cfg.store.removeItem(cfg.uuid);
// 				}
// 			}
// 		} catch (ex) {
// 			console.error('Unable invalidate cache');
// 		}
// 	},

// 	exclude: {
// 		query: false,
// 		filter: (cfg, res) => {
// 			console.log('filter arcfggs ', cfg);
// 			console.log('filter res ', res);
// 		}
// 	}
// });

// TODO: Add cache busting logic
class Api {
  httpClient: AxiosInstance;
  constructor() {
    // Set defaults for API endpoint
    axios.defaults.baseURL = `${process.env.REACT_APP_E4_API_ENDPOINT}`;

    this.httpClient = axios.create({
      withCredentials: true,
      // adapter: process.env.REACT_ENABLE_CACHE ? cache.adapter : null,
      headers: {
        "Content-Type": "application/json",
        "ev-sessionid": generateSession(),
        Accept: "application/json, text/plain, */*",
      },
    });

    this.httpClient.interceptors.request.use(accessTokenInterceptor);
    this.httpClient.interceptors.response.use(async (response) => {
      return response;
    }, responseRefreshTokenInterceptor);
  }

  // eslint-disable-next-line class-methods-use-this
  createInstance() {
    return new Api();
  }

  request(config?: any) {
    this.httpClient.request(config);
  }

  get(url: string, config: AxiosRequestConfig = {} as AxiosRequestConfig) {
    //const { cacheKey } = config;
    const cacheKey = "";

    if (cacheKey !== null && cacheKey !== undefined) {
      Object.assign(config, { cache: { key: cacheKey } });
    }

    return this.httpClient
      .get(url, config)
      .then(fetchStatusHandler)
      .then((response: AxiosResponse) => response.data);
  }

  head(url: string, config: AxiosRequestConfig = {}) {
    return this.httpClient
      .head(url, config)
      .then(fetchStatusHandler)
      .then((response: AxiosResponse) => response.data);
  }

  post(url: string, data: any, config: AxiosRequestConfig = {}) {
    return this.httpClient
      .post(url, data, config)
      .then(fetchStatusHandler)
      .then((response: AxiosResponse) => response.data);
  }

  put(url: string, data: any, config: AxiosRequestConfig = {}) {
    return this.httpClient
      .put(url, data, config)
      .then(fetchStatusHandler)
      .then((response: AxiosResponse) => response.data);
  }

  patch(url: string, config: AxiosRequestConfig = {}) {
    return this.httpClient
      .patch(url, config)
      .then(fetchStatusHandler)
      .then((response: AxiosResponse) => response.data);
  }

  delete(url: string, config: AxiosRequestConfig = {}) {
    return this.httpClient
      .delete(url, config)
      .then(fetchStatusHandler)
      .then((response: AxiosResponse) => response.data);
  }
}

export const api = new Api();
