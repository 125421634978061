import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// Material-UI
import { Theme, useTheme, alpha } from "@mui/material/styles";
import  { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

interface Props {
  icon: any;
  url: string;
  hash?: string;
  name: string;
}

const AppMenuItem: React.FC<Props> = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const hashIndex = location.hash.indexOf("/", 2);
    const hash =
      hashIndex === -1 ? location.hash : location.hash.substr(0, hashIndex);
    const propsHash = props.hash ? `#${props.hash}` : "";
    const app = location.pathname.split("/")[1];

    setSelected(props.url + propsHash === `/${app}${hash}`);
  }, [props.url, props.hash, location]);

  // const handleClick = () => {
  // 	if(props.url.indexOf('#') !== -1) {
  // 		window.location.hash = props.url;
  // 	}
  // };

  return (
    // <Link component={RouterLink} to={{ pathname: props.url, hash: props.hash || "", state: { from: location.pathname } }} underline="none" variant="body2" className={classes.link} onClick={handleClick}>
    <Link
      component={RouterLink}
      to={`${props.url}${props.hash ? `#${props.hash}` : ""}`} // {{ pathname: props.url, hash: props.hash || "", state: { from: location.pathname } }}
      underline="none"
      variant="body2"
      className={classes.link}
    >
      <ListItem button data-selected={selected}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.name} />
      </ListItem>
    </Link>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    "& > .MuiListItem-root": {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0, 0, 0, 1),
      "& > .MuiListItemIcon-root": {
        color: theme.palette.text.secondary,
        minWidth: 30,
        marginTop: -2,
        "& > .MuiSvgIcon-root": {
          fontSize: `2rem`,
        },
      },
      "& > .MuiListItemText-root": {
        color: theme.palette.text.primary,
        "& > .MuiTypography-displayBlock": {},
      },
      '&[data-selected="true"]': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        "& > .MuiListItemText-root": {
          color: theme.palette.primary.main,
          "& > .MuiTypography-displayBlock": {
            // fontWeight: 500,
          },
        },
        "& > .MuiListItemIcon-root": {
          color: theme.palette.primary.main,
        },
      },
      '&:not([data-selected="true"]):hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.03),
        "& > .MuiListItemText-root": {
          color: theme.palette.primary.main,
        },
        "& > .MuiListItemIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

export default AppMenuItem;
