import React from "react";
import { Outlet } from "react-router-dom";

// Material-UI
import { Theme } from "@mui/material/styles";
import  { makeStyles } from "@mui/styles";

interface PropsWithChildren {}

const AuthLayout: React.FC<PropsWithChildren> = (props) => {
  const classes = useStyles();

  return (
    <>
      {/* <Container className={classes.root} maxWidth={'lg'}> */}
      <main className={classes.main}>
        <Outlet />
      </main>
      {/* </Container> */}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}));

export default AuthLayout;
