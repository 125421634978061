import { gql } from "@apollo/client";

export const FETCH_APP_DATA = gql`
  query fetchApplicationData {
    settings {
      resultsPerPage
      currency
      siteName
      logoPath
      themeName
      products {
        resultsPerPage
      }
      customers {
        resultsPerPage
      }
    }
  }
`;
