import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CampaignIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,2 L9,10.553 L21,19 L21,2 z M6,12 L6,20 L9,20 L9,12 L6,12 z M4.078,7 C3.483,7 3,7.418 3,7.933 L3,13.067 C3,13.582 3.483,14 4.078,14 L14.922,14 C15.517,14 16,13.582 16,13.067 L16,7.933 C16,7.418 15.517,7 14.922,7 L4.078,7 z"></path>
    </SvgIcon>
  );
};

export default CampaignIcon;
