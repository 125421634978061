import i18n from "i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import validationEN from "./locales/en/validation.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: translationEN,
        validation: validationEN,
      },
    },
    ns: ["translation", "validation"],
    defaultNS: "translation",
  });

export default i18n;
