import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

// API
import { IS_LOGGED_IN } from "graphql/queries/isLoggedIn";

// Components
import Header from "components/Layout/Header";
import AppMenu from "components/Layout/AppMenu";
import SnackbarAlert, { iSnackbarAlert } from "components/SnackbarAlert";
import { useGlobalContext } from "globalContext";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

// Material-UI
import { Theme } from "@mui/material/styles";
import  { makeStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { Toolbar } from "@mui/material";

interface PropsWithChildren {}

const DefaultLayout: React.FC<PropsWithChildren> = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { state: message } = useGlobalContext();

  const { data, loading } = useQuery(IS_LOGGED_IN);

  const authenticated = data?.isLoggedIn || false;

  // State
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (loading === false && authenticated === false) {
      navigate("/login");
      // <Navigate to={{ pathname: '/login'}} state={{ from: location }}  />
    }
  }, [authenticated, loading]);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  return (
    <div className={classes.root}>
      <Header>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawerOpen((value) => !value)}
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </Header>

      <div className={classes.body}>
        <Hidden mdDown>
          <div className={classes.drawer}>
            <div className={classes.scroll}>
              <AppMenu />
            </div>
          </div>
        </Hidden>

        <main className={classes.main}>
          <div className={classes.scroll}>
            <Outlet />
          </div>
        </main>
      </div>

      <Drawer
        open={drawerOpen}
        elevation={2}
        onClose={() => setDrawerOpen(false)}
      >
        <Toolbar variant="dense" className={classes.logoBar}>
          <img alt="logo" src="/logo-white.svg" className={classes.logo} />
          <div className="grow"></div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <div className={classes.drawer}>
          <div className={classes.scroll}>
            <AppMenu />
          </div>
        </div>
      </Drawer>

      <SnackbarAlert
        open={message.open}
        severity={message?.severity}
        message={message?.message}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    flex: "1 0 0px",
    display: "flex",
  },
  drawer: {
    width: 240,
    display: "flex",
    flexDirection: "column",
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      flex: "1 0 0px",
      width: 300,
      maxWidth: "95vw",
      borderRightWidth: 0,
    },
  },
  main: {
    flex: "1 0 0px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  },
  logoBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  logo: {
    maxHeight: 40,
  },
  scroll: {
    flex: "1 0 0px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

export default DefaultLayout;
