import React, { Suspense } from "react";
import {
  Routes as ReactRoutes,
  Route,
} from "react-router-dom";

// Components
import Loading from "components/Layout/Loading";

// Layouts
import AuthLayout from "pages/_layouts/auth";
import DefaultLayout from "pages/_layouts/default";

// Routes
const Studio = React.lazy(() => import("pages/studio"));
const Login = React.lazy(() => import("pages/login"));
const NotFound = React.lazy(() => import("pages/not-found"));
const ForgotPassword = React.lazy(() => import("pages/forgot-password"));
const ResetPassword = React.lazy(() => import("pages/reset-password"));

// Apps
const Home = React.lazy(() => import("pages/home"));
const Articles = React.lazy(() => import("pages/articles"));
const Campaigns = React.lazy(() => import("pages/campaigns"));
const Broadcasts = React.lazy(() => import("pages/broadcasts"));
const Profile = React.lazy(() => import('pages/profile'));
const ChangePassword = React.lazy(() => import('pages/profile/ChangePassword'));
const SiteComponents = React.lazy(() => import("pages/site-components"));
const Settings = React.lazy(() => import("pages/settings"));
const SettingsGeneral = React.lazy(() => import("pages/settings/general"));
const SettingsRolesAndPermissions = React.lazy(() => import("pages/settings/roles-permissions"));
const SettingsSearchEngine = React.lazy(() => import("pages/settings/search-engine"));
const SettingsEmployee = React.lazy(() => import("pages/settings/employees"));

export default function Routes() {
  return (
    <Suspense fallback={<Loading fullscreen />}>
      <ReactRoutes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/studio/*" element={<Studio />} />
          <Route path="/articles/*" element={<Articles />} />
          <Route path="/campaigns/*" element={<Campaigns />} />
          <Route path="/broadcasts/*" element={<Broadcasts />} />
          <Route path="/site-components/*" element={<SiteComponents />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/change-password" element={<ChangePassword />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/general/*" element={<SettingsGeneral />} />
          <Route path="/settings/roles-permissions/*" element={<SettingsRolesAndPermissions />} />
          <Route path="/settings/search-engine/*" element={<SettingsSearchEngine />} />
          <Route path="/settings/employees/*" element={<SettingsEmployee />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </ReactRoutes>
    </Suspense>
  );
}
